import React from "react";
import { getMainMenu } from "../services/API";
import Layout from "../components/layout";
import { Hero, Section } from "../components/";

export const getStaticProps = async ({ params, locale }) => {
  const [mainMenuData, footerData] = await Promise.all([
    getMainMenu("mainMenu", locale, "header"),
    getMainMenu("mainMenu", locale, "footer"),
  ]);

  return {
    props: {
      mainMenuData,
      footerData,
    },
    revalidate: 30,
  };
};

const Page = ({ mainMenuData, footerData }) => {
  return (
    <>
      <Layout footerData={footerData} mainMenuData={mainMenuData}>
        <Section variant="dark">
          <Hero text="404 | Page Not Found" scrollto={false} />
        </Section>
      </Layout>
    </>
  );
};
export default Page;
